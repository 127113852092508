import styles from './loader.module.css'

const Loader = () => {
  return (
    <div className={styles.loaderContainer}>
      <div className={styles.loaderContent}></div>
    </div>
  )
}
export default Loader
